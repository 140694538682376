"use client";

import MuxPlayer, { MuxPlayerProps } from "@mux/mux-player-react";

export default function VideoPlayer({
  ...props
}: MuxPlayerProps) {
  return <MuxPlayer
    {...props}
  />
}
